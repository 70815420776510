import { computed, defineComponent, inject, onBeforeMount } from 'vue';
import RankingClasificationBox from '@/components/RankingClasificationBox.vue';
export default defineComponent({
    components: { RankingClasificationBox: RankingClasificationBox },
    setup: function () {
        var configurationStore = inject('configurationStore');
        var authStore = inject('authState');
        var userData = computed(function () { return authStore.state.userData; });
        var ranking = computed(function () { return configurationStore.state.ranking.ranking; });
        var topRanking = computed(function () { return configurationStore.state.ranking.topRanking; });
        var getRanking = function () {
            configurationStore.getRanking();
        };
        var getTopRanking = function () {
            configurationStore.getTopRanking();
        };
        var getLevelStatus = function (type) {
            switch (type) {
                case 'REGULAR':
                    return 'PádaWAT';
                case 'PRO':
                    return 'WATpro';
                case 'GURU':
                    return 'WATguru';
                default:
                    break;
            }
        };
        onBeforeMount(function () {
            getRanking();
            getTopRanking();
        });
        return {
            ranking: ranking,
            userData: userData,
            getLevelStatus: getLevelStatus,
            topRanking: topRanking,
        };
    },
});
