import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        value: {}
    },
    setup: function () {
        var getLevelStatus = function (type) {
            switch (type) {
                case 'REGULAR':
                    return 'PádaWAT';
                case 'PRO':
                    return 'WATpro';
                case 'GURU':
                    return 'WATguru';
                default:
                    break;
            }
        };
        return {
            getLevelStatus: getLevelStatus
        };
    }
});
